/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin span.multiSelect {
  position: relative;
}
.altai-theme-admin span.multiSelect .checkboxLayer {
  position: absolute;
}
.altai-theme-admin .altai-multi-select .query {
  width: 100%;
  background-color: #EBEBEB;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
}
.altai-theme-admin .altai-multi-select .query input {
  flex: 1;
  padding: 7px;
  border: 0;
  background-color: #fEFEFE;
  width: 100%;
  width: calc(98%);
}
.altai-theme-admin .altai-multi-select .collection {
  background-color: #EBEBEB;
  padding: 10px 0px 10px 0px;
  min-height: 200px;
}
.altai-theme-admin .altai-multi-select .collection .group {
  padding-left: 2%;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.altai-theme-admin .altai-multi-select .collection .item {
  width: 100%;
  height: 40px;
  margin-left: 5px;
  cursor: pointer;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
}
.altai-theme-admin .altai-multi-select .collection .item .check {
  color: #0068ff;
  width: 5%;
  text-align: center;
}
.altai-theme-admin .altai-multi-select .collection .item:hover {
  background-color: #f7f7f7;
}
